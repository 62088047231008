.swiper {
    width: 100%;
    height: auto;
  }

.swiper-slide {
    display: flex;
    justify-content: center;
}

img.slider-image {
    width: 100%;
    height: 280px;
    object-fit: cover;
}