a {
    color: #FFF;
}
a:hover {
    color: #D3D3D8;
}
a.MenuItem {
    color: #000;
}
a.MenuItem:hover {
    color: #000;
}